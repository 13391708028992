export const TanksList = [
  {
    id: 114654,
    category: "tanks",

    price: 125690,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-3000l-vertikal-uz-s/",
    img: "ИМП-ТВ-114654.webp",
    name: "Вертикальный бак",
    volume: 3000,
    layer: 3,
    size: "158 х 190",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114655,
    category: "tanks",
    price: 88910,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-2000l-gorizontal-uz-s/",
    img: "ИМП-ТВ-114655.webp",
    name: "Горизонтальный бак",
    volume: 2000,
    layer: 3,
    size: "148 х 194 х 136",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114656,
    category: "tanks",
    price: 46195,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-1000l-3-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114656.webp",
    name: "Горизонтальный бак",
    volume: 1000,
    layer: 3,
    size: "122 x 155 х 109",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114657,
    category: "tanks",
    price: 59625,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-1000l-3-kh-sl-pryamoug-uz-s/",
    img: "ИМП-ТВ-114657.webp",
    name: "Горизонтальный бак",
    volume: 1000,
    layer: 3,
    size: "150 х 125 х 70",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114658,
    category: "tanks",
    price: 34190,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-1000l-2-kh-sl-vertikal-uz-s/",
    img: "ИМП-ТВ-114658.webp",
    name: "Вертикальный бак",
    volume: 1000,
    layer: 2,
    size: "136 х 75 х 97",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114659,
    category: "tanks",
    price: 68025,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-1500l-gorizontal-uz-s/",
    img: "ИМП-ТВ-114659.webp",
    name: "Горизонтальный бак",
    volume: 1500,
    layer: 3,
    size: "120 x 180",
    color: "зеленый",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114660,
    category: "tanks",
    price: 41455,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-800l-gorizontal-uz-s/",
    img: "ИМП-ТВ-114660.webp",
    name: "Горизонтальный бак",
    volume: 800,
    layer: 2,
    size: "108 х 130 х 100",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114661,
    category: "tanks",
    price: 51105,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-800l-3-kh-sl-kvadrat-new-uz-s/",
    img: "ИМП-ТВ-114661.webp",
    name: "Квадратный бак",
    volume: 800,
    layer: 3,
    size: "122 х 155 х 109",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114662,
    category: "tanks",
    price: 40885,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-750l-pryamoug-new-uz-s/",
    img: "ИМП-ТВ-114662.webp",
    name: "Прямоугольный бак",
    volume: 750,
    layer: 3,
    size: "135 х 95 х 70",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114663,
    category: "tanks",
    price: 29530,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-600l-gorizontal-uz-s/",
    img: "ИМП-ТВ-114663.webp",
    name: "Горизонтальный бак",
    volume: 600,
    layer: 3,
    size: "105 х 132 х 94",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114664,
    category: "tanks",
    price: 28960,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-500l-3-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114664.webp",
    name: "Горизонтальный бак",
    volume: 500,
    layer: 3,
    size: "120 х 85 х 90",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114665,
    category: "tanks",
    price: 24985,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-500l-2-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114665.webp",
    name: "Горизонтальный бак",
    volume: 500,
    layer: 2,
    size: "124 х 72 х 72",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114666,
    category: "tanks",
    price: 21010,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-500l-vertikal-uz-s/",
    img: "ИМП-ТВ-114666.webp",
    name: "Вертикальный бак",
    volume: 500,
    layer: 2,
    size: "108 х 130",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114667,
    category: "tanks",
    price: 26690,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-500l-kvadrat-uz-s/",
    img: "ИМП-ТВ-114667.webp",
    name: "Квадратный бак",
    volume: 500,
    layer: 3,
    size: "45 х 100 х 145",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114668,
    category: "tanks",
    price: 21470,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-400l-3-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114668.webp",
    name: "Горизонтальный бак",
    volume: 400,
    layer: 3,
    size: "110 х 80 х 85",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114669,
    category: "tanks",
    price: 18860,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-300l-kvadrat-uz-s/",
    img: "ИМП-ТВ-114669.webp",
    name: "Квадратный бак",
    volume: 300,
    layer: 3,
    size: "40 х 88 х 123",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114670,
    category: "tanks",
    price: 14530,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-300l-vertikal-uz-s/",
    img: "ИМП-ТВ-114670.webp",
    name: "Вертикальный бак",
    volume: 300,
    layer: 3,
    size: "72 х 100",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114671,
    category: "tanks",
    price: 20445,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-300l-gorizontal-uz-s/",
    img: "ИМП-ТВ-114671.webp",
    name: "Горизонтальный бак",
    volume: 300,
    layer: 3,
    size: "136 х 75 х 97",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114672,
    category: "tanks",
    price: 18005,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-300l-2-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114672.webp",
    name: "Горизонтальный бак",
    volume: 300,
    layer: 2,
    size: "136 х 75 х 97",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114673,
    category: "tanks",
    price: 21755,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-250l-3-kh-sl-kvadrat-uz-s/",
    img: "ИМП-ТВ-114673.webp",
    name: "Квадратный бак",
    volume: 250,
    layer: 3,
    size: "110 х 110 х 13",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114674,
    category: "tanks",
    price: 13630,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-250l-3-kh-sl-gorizontal-uz-s/",
    img: "ИМП-ТВ-114674.webp",
    name: "Горизонтальный бак",
    volume: 250,
    layer: 3,
    size: "95 х 72 х 65",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114675,
    category: "tanks",
    price: 10510,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bak-200l-2-kh-sl-vertikal-uz-s/",
    img: "ИМП-ТВ-114675.webp",
    name: "Вертикальный бак",
    volume: 200,
    layer: 2,
    size: "70 х 67",
    color: "синий",
    discription:
      "Современные баки для хранения жидкостей представляют собой высокоэффективные решения, объединяя в себе прочность и многослойную структуру для широкого спектра применений. Их уникальная конструкция обеспечивает безопасное и эффективное хранение различных жидкостей, сохраняя их свойства и качество. Независимо от бытового использования, промышленных задач или потребности в перевозке, эти многофункциональные баки предоставляют надежное решение для хранения и транспортировки жидкостей.",
  },
  {
    id: 114677,
    category: "barrels",
    price: 4045,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-120l-uz-s/",
    img: "ИМП-ТВ-114677.webp",
    name: "Бочка",
    volume: 120,
    layer: 1,
    size: "80 х 45",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114678,
    category: "barrels",
    price: 3630,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-100l-uz-s/",
    img: "ИМП-ТВ-114678.webp",
    name: "Бочка",
    volume: 100,
    layer: 1,
    size: "75 х 45",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114679,
    category: "barrels",
    price: 2725,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-80l-uz-s/",
    img: "ИМП-ТВ-114679.webp",
    name: "Бочка",
    volume: 80,
    layer: 1,
    size: "65 х 40",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114680,
    category: "barrels",
    price: 2495,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-60l-uz-s/",
    img: "ИМП-ТВ-114680.webp",
    name: "Бочка",
    volume: 60,
    layer: 1,
    size: "60 х 38",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114681,
    category: "barrels",
    price: 2330,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-50l-s-naves-ruch-uz-s/",
    img: "ИМП-ТВ-114681.webp",
    name: "Бочка",
    volume: 50,
    layer: 1,
    size: "55 х 35",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114682,
    category: "barrels",
    price: 1880,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-40l-uz-s/",
    img: "ИМП-ТВ-114682.webp",
    name: "Бочка",
    volume: 40,
    layer: 1,
    size: "53 х 33",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114683,
    category: "barrels",
    price: 1520,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-30l-uz-s/",
    img: "ИМП-ТВ-114683.webp",
    name: "Бочка",
    volume: 30,
    layer: 1,
    size: "40 х 33",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114684,
    category: "barrels",
    price: 1005,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bidon-22l-krug-uz-s/",
    img: "ИМП-ТВ-114684.webp",
    name: "Круглая бочка",
    volume: 22,
    layer: 1,
    size: "45 х 27",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114686,
    category: "barrels",
    price: 1520,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-20l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114686.webp",
    name: "Квадратная бочка",
    volume: 20,
    layer: 1,
    size: "30 х 30",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114687,
    category: "barrels",
    price: 1855,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-30l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114687.webp",
    name: "Квадратная бочка",
    volume: 30,
    layer: 1,
    size: "30 х 38",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114688,
    category: "barrels",
    price: 2320,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-40l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114688.webp",
    name: "Квадратная бочка",
    volume: 40,
    layer: 1,
    size: "38 х 40",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114689,
    category: "barrels",
    price: 2730,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-50l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114689.webp",
    name: "Квадратная бочка",
    volume: 50,
    layer: 1,
    size: "35 х 48",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114690,
    category: "barrels",
    price: 3045,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-60l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114690.webp",
    name: "Квадратная бочка",
    volume: 60,
    layer: 1,
    size: "36 х 54",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114691,
    category: "barrels",
    price: 3635,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-80l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114691.webp",
    name: "Квадратная бочка",
    volume: 80,
    layer: 1,
    size: "37 х 64",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114692,
    category: "barrels",
    price: 4555,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-100l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114692.webp",
    name: "Квадратная бочка",
    volume: 100,
    layer: 1,
    size: "40 х 66",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
  {
    id: 114693,
    category: "barrels",
    price: 5795,
    url_zetakz:
      "https://www.zeta.kz/catalog/tovary-dlya-doma/khozyaystvennye-tovary/baki-dlya-vody/bochka-120l-kvadrat-gusenitsa-uz-s/",
    img: "ИМП-ТВ-114693.webp",
    name: "Квадратная бочка",
    volume: 120,
    layer: 1,
    size: "42 х 70",
    color: "синий",
    discription:
      "Универсальные бочки для хранения жидкостей представляют собой надежное и простое в использовании решение для различных областей применения. Имея однослойную конструкцию, эти бочки обеспечивают легкость в обращении, сохраняя при этом высокую степень функциональности. Они идеально подходят для использования в различных сферах, от бытовых нужд до промышленных процессов. Выполненные из высококачественных материалов, бочки обеспечивают надежное и безопасное хранение разнообразных жидкостей, сохраняя их свойства. Эта простота использования и универсальность делают эти бочки незаменимыми в разнообразных областях применения, обеспечивая удобство и эффективность хранения жидкостей.",
  },
];
